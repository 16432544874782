const resource = {
    en: {
        reset_password_request_sent: 'Reset Password Mail Requested',
        reset_password_request_sent_message: 'You have sent a request to reset your password. You will soon receive a mail with a link to reset your password.',
        home_button: 'Back to Home'
    },
    de: {
        reset_password_request_sent: 'Rücksetzung Ihres Passwortes angefordert',
        reset_password_request_sent_message: 'Sie haben die Rücksetzung Ihres Passwortes angefordert und werden in Kürze eine E-Mail mit einem Link zur Rücksetzung des Passwortes erhalten.',
        home_button: 'Zurück zur Homepage'
    }
}

export default resource;