<template>
    <div class="authentication-card">
        <h1 class="authentication-heading" v-html="resource[lang].reset_password_request_sent" />
        <p class="authentication-text" v-html="resource[lang].reset_password_request_sent_message" />
        <Button stretch rounded submit
            class="authentication-button" 
            color="primary" 
            :label="resource[lang].home_button" 
            @click="$router.push('/')"
        />
    </div>
</template>

<script>
import resource from '@/assets/resources/forgot-password-confirmation-resource'

import Button from '@/components/basic/Button'

export default {
    components: {
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
}
</script>